html {
  overflow:hidden;
}
body {
  font-variant-ligatures:none;
}
body .grid-container * {
  border-color:transparent !important;
}
.mobile .grid-container {
  padding:0px;
}
@font-face {
  font-family: 'FloatingMono';
  src: local('FloatingMono'), url(./fonts/FloatingMono-CondBlack525.otf) format('opentype');
  font-weight:525;
}
@font-face {
  font-family: 'FloatingMono';
  src: local('FloatingMono'), url(./fonts/FloatingMono-CondLight450.otf) format('opentype');
  font-weight:450;
}
@font-face {
  font-family:'FloatingMono';
  src: local('FloatingMono'), url(./fonts/FloatingMono-CondBlack600_One.otf) format('opentype');
  font-weight:600;
}
@font-face {
  font-family:'NimbusRom';
  src: local('NimbusRom'), url(./fonts/NimbusRomNo9L-Med.otf) format('opentype');
  font-weight:500;
}
@font-face {
  font-family:'NimbusRom';
  src: local('NimbusRom'), url(./fonts/NimbusRomNo9L-Reg.otf) format('opentype');
  font-weight:normal;
}
@font-face {
  font-family:'NimbusRom';
  src: local('NimbusRom'), url(./fonts/NimbusRomNo9L-RegIta.otf) format('opentype');
  font-weight:normal;
  font-style: italic;
}
@font-face {
  font-family:'cmuntb';
  src: local('cmuntb'), url(./fonts/cmuntb.otf) format('opentype');
}
@font-face {
  font-family:'nimbus';
  src: local('nimbus'), url(./fonts/nimbus-sans-l_bold.ttf) format('opentype');
}
@font-face {
  font-family:'NimbusSansCond';
  src: local('NimbusSansCond'), url(./fonts/NimbusSansLFloated-ReguCond.otf) format('opentype');
}
@font-face {
  font-family:'Compagnon';
  src: local('Compagnon'), url(./fonts/Compagnon-Bold.otf) format('opentype');
  font-weight:bold;
}
@font-face {
  font-family:'MinionPro';
  src: local('MinionPro'), url(./fonts/MinionPro-Regular.otf) format('opentype');
}
@font-face {
  font-family:'FaroBold';
  src: local('FaroBold'), url(./fonts/2023_fonts/Faro-BoldLucky.otf) format('opentype');
}
@font-face {
  font-family:'Faro';
  src: local('Faro'), url(./fonts/2023_fonts//Faro-RegularLucky.otf) format('opentype');
  font-weight:normal;
}
@font-face {
  font-family:'Violet';
  src: local('Violet'), url(./fonts/2023_fonts/V1OLET-Regular.otf) format('opentype');
}
@font-face {
  font-family:'Waverse';
  src: local('Waverse'), url(./fonts/2023_fonts/Waverse0.1-Bold.otf) format('opentype');
}
/**********************/
/* GRID */
.grid-container-container {
  /* This is now set by the code, per year
  background-image:url("./media/square.png"); */
  background-size: 3.073vw 3.125vw;
  background-position: 8px 0px;
  /* height:62.5vw; */
  height:63.5vw;
}
.grid-container {
  height:100%;
  /* height:calc(100vh - 60px); */
}
.cell {
  background:transparent !important;
}
/* GRID MOBILE */
.mobile.horizontal .mobile .grid-container-container {
  background-size:6.25vw 6.25vw;
}
.mobile .grid-container-container {
  background-size:12.5vw 12.5vw;
  background-position: 0px 0px;
  /* height: 564vh; */
  height:100%;
}
.mobile .schedule {
  width: 100%;
  height: auto;
}
.schedule {
  position:absolute;
  left:0px;
  width:100%;
  height:100vh;
  /* height:100%; */
}

/* HEADER */
.app-header *:visited,
.app-header *:hover {
  color:black;
}
.main-header {
  cursor:pointer;
}
.app-header {
  min-height:60px;
  width:100%;
  display:flex;
  justify-content:space-between;
  box-sizing:border-box;
  color:#231f21;
  letter-spacing:1.5px;
  padding:15px 10px;
}
/* LEFT */
.app-header-left {
  font-size:2.3vw;
  text-align:left;
  font-family:'FloatingMono';
  font-weight:600;
  display:flex;
  flex-direction:column-reverse;
}
.year-2023 .app-header-left {
  font-size:3vw;
  text-align:left;
  font-family:'Violet';
  display:flex;
  flex-direction:column-reverse;
  font-weight:350;
}
/* CENTER */
.app-header-center:visited {
  color:black;
  text-decoration:none;
}
.app-header-center {
  line-height: 1.2vw;
  padding-top: 0.4vw;
  font-size:1.2vw;
  color:black;
  text-decoration:none;
  letter-spacing:2px;
  text-align:center;
  font-family:'NimbusRom';
  display:flex;
  flex-direction:column-reverse;
}
.year-2021 .app-header-center {
  font-family:'NimbusSansCond';
  font-size:1.3em;
}
.year-2021 .app-header-center em{
  font-family:'Compagnon';
  display:inline;
  letter-spacing:1px;
}
.year-2023 .app-header-center {
  font-family:'Faro';
  font-size:1.2em;
  letter-spacing: 1px;
}
.year-2023 .app-header-center .scaley{
  font-family:'Waverse';
  transform: scaleY(2.6);
  font-size: 0.5em;
  float: inline-start;
}
.scaley{
  padding-right: 5px;
}
.year-2023 .app-header-center .inline{
  float:inline-end;
}
.year-2023 .app-header-center:hover .inline{
  text-decoration: underline;
}
.year-2023 .app-header-center:hover .scaley {
  text-decoration: underline;
}
/* RIGHT */
.app-header-right:visited {
  text-decoration:none;
}
.app-header-right {
  font-size:2vw;
  text-align:right;
  text-decoration:none !important;
  color:black;
  font-family:'FloatingMono';
  font-weight:bold;
  display:flex;
  flex-direction:column-reverse;
}


.navigation {
  list-style: none;
  padding: 0;
  margin: 0 auto !important;
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 11vw;
}
.year-2021 .navigation {
  width:15vw;
}
.year-2023 .navigation {
  width:15vw;
}

.mobile .navigation {
  width: 29%;
}

.navigation li {
  display: block;
  flex: 0 1 auto; /* Default */
  list-style-type: none;
  font-family:'nimbus';
  font-size: 0.8vw;
  line-height: 0.8vw;
}
.year-2021 .navigation li {
  font-family:'Compagnon';
  font-weight:normal;
  font-size:1.4vw;
  margin:0.5em 1em 0 0.2em;
}
.year-2023 .navigation li {
  font-family:'Faro';
  font-weight:normal;
  font-size:1.4vw;
  margin:0.5em 1em 0 0.2em;
}
.year-2021 .navigation li:after{
  content:" - ";
  display:block;
  position:relative;
  top:-10px;
  right:-1em;
}
.year-2021 .navigation li:last-child:after{
  content:none;
}

.mobile .navigation li {
  font-size: 2vw;
  line-height: 2vw;
}

.navigation a {
  color: black;
  font-weight: normal;
}

.navigation .selected {
  font-weight: bolder;
}

/* BOTTOM */
.app-header-bottom {
  text-align:center;
}

/* MOBILE HEADER */
.mobile .app-header {
  display: flex;
  flex-direction: column;
  padding:10px;
}
.mobile .app-header {
  height:110px;
}
.mobile.horizontal .app-header {
  height:140px;
}
.mobile.horizontal .app-header-center {
  padding-top: 10px;
  font-size: 2vw;
  line-height:2.5vw;
}
.mobile .app-header-center {
  margin-top: 6px;
  font-size: 2.5vw;
  line-height:3.2vw;
}
.mobile .app-header-center .scaley {
  float: none;
}
.mobile .app-header-center .inline{
  float: none;
}
.mobile.horizontal .app-header-left,
.mobile.horizontal .app-header-right {
  font-size:4vw;
}
.mobile.horizontal .app-header-left {
  line-height:3vw;
}
.mobile .app-header-left,
.mobile .app-header-right {
  font-size:5vw;
}
.mobile .app-header-links {
  justify-content:center;
}
/* UTIL */
.sample-img {
  position:absolute;
  left:0px;
  width:100%;
  height:100%;
  display:none;
}
.strikethrough {
  position:relative;
}
.strikethrough:after {
  background:#231f21;
  content:'';
  width:100%;
  position:absolute;
  left:0px;
  height:40%;
  top:50%;
  transform:translateY(-50%);
}

/* WINDOW */
.window-header br {
  display:none;
  margin-top:8px;
  margin-bottom:4px;
}
.window.alt .window-header {
  margin-top:12px;
  font-family:'NimbusRom';
  line-height:22px;
  font-size:20px;
  margin-bottom:0px;
}
.window-header {
  margin-bottom:4px;
  margin-top:6px;
  letter-spacing:1px;
  text-align:left;
  line-height:24px;
  font-size:22px;
  font-family:'FloatingMono';
}
.window-subheader.caption {
  margin-top:5px;
}
.window-subheader {
  text-align:left;
  font-size:10px;
  font-family:'nimbus';
}
.window-content {
  margin-top:20px;
  font-family:'NimbusRom';
  text-align:left;
}
.text-faro{
  font-family: sans-serif;
  font-size: 0.8em;
}
.underline{
  text-decoration: underline;
}
.window-content h1{
  font-size: 1.3em;
  text-decoration: underline;
}
.window-content h2{
  font-size: 1.3em;
}
.pdf{
 color: black;
}
.pdf a{
  color: black;
 }
.window {
  /* overflow:scroll; */
  overflow:hidden;
  padding:10px 30px 30px 30px;
  cursor:normal;
  width:300px;
  height:300px;
  position:absolute;
  border:2px solid #231f21;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  overflow:hidden;
  display: flex !important;
  flex-direction: column;
}
.year-2019 .window {
  background-color:white;
  border-color:#231f21;
}
.year-2021 .window {
  background-color:white;
  border-color:#231f21;
}
.year-2023 .window {
  background-color:white;
  border-color:#231f21;
}
.window-content-container {
  margin-bottom:10px;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow:scroll;
}
.mobile .window.pdf {
  width: calc(100% - 10px);
  padding: 20px 10px;
}
.mobile .window {
  left: 50%;
  top: 50% !important;
  transform: translate(-50%,-50%);
  height: calc(100% - 15px);
  width: calc(100% - 20px);
  box-sizing: border-box;
  padding: 20px;
}

.window-close-btn {
  transform:scale(1.3);
  cursor:pointer;
  width: 28px;
  position: absolute;
  height: 27px;
  left: 0;
  top: 0;
}

.window-close-btn:after {
  position:absolute;
  font-family:"NimbusRom";
  content:'x';
  font-size:11px;
  cursor:pointer;
  left:10px;
  top:8px;
}

.grid-container {
  padding:0px 8px;
}
.row {
  position:relative;
  border-left:10px solid rgba(0,0,0,0.4);
  display:flex;
}
.mobile.horizontal .cell {
  font-size:4vw;
  line-height:4.5vw;
}
.mobile.horizontal .cell.alt {
  font-size:2.5vw;
  line-height:3.0vw;
}
.mobile .cell.alt {
  font-size:5.05vw;
  line-height:5.5vw;
}
.mobile .cell {
  /* font-size:9.15vw; */
  font-size:8.4vw;
  line-height:8.5vw;
}
.mobile .cell:not(.alt) .cell-author {
  font-size:3vw;
  line-height:3vw;
}
.cell.alt {
  font-family:"NimbusRom";
  font-weight:normal;
  font-size:1.15vw;
  line-height:1.5vw;
}
.year-2021 .cell.alt {
  font-family:"Compagnon";
  font-weight:bold;
  font-size:1.4vw;
  line-height:1.2vw;
}
.year-2023 .cell.font-1 {
  font-family:"Waverse";
  font-size:2.4vw;
  line-height:1.8vw;
  transform:scaleY(1.9);
  transform:scaleX(0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.year-2023 .cell.font-2 {
  font-family:"FaroBold";
  font-size:1.3vw;
  line-height:1.2vw;
  letter-spacing: -0.3px;
}
.year-2023 .cell.font-3 {
  font-family:"Violet";
  font-size:3vw;
  line-height:1.6vw;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.year-2023 .cell.font-3 .adjust{
  white-space: nowrap;
}
.year-2023 .cell.font-3 em {
  font-family:"Waverse";
  font-size:2.2vw;
  line-height:1.8vw;
  transform:scaleY(1.9);
  transform:scaleX(0.5);
  letter-spacing: 0.5px;
}

em {
  font-style:normal;
  transform:translateY(-0.15em);
  display: inline-block;
}
.cell:not(.alt) {
  letter-spacing:1.3px;
}
.flip-letter {
  transform: scaleX(-1);
  display: inline-block;
  margin-right: 1px;
}
.cell-content {
  padding-top: 2px;
}
.mobile.horizontal .cell {
  width:25vw;
  height:25vw;
  max-width:25vw;
  max-height:25vw;
}
.mobile .cell {
  width:50vw;
  height:50vw;
  max-width:50vw;
  max-height:50vw;
}
/* .mobile.horizontal .cell { */
  /* font-size:2.1vw; */
  /* line-height:2vw; */
/* } */
.cell {
  width:12.5vw;
  max-width:12.5vw;
  height:12.5vw;
  max-height:12.5vw;

  /* overflow:hidden; */
  font-family:'FloatingMono';
  font-weight:525;
  font-size:2.1vw;
  line-height:1.8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  flex-grow: 1;
  flex-basis: 0;
  border-right:1px solid;
  /* border-bottom:1px solid; */
  position:relative;
  transition:0.4s ease-in-out;
  background:white;
  color:black;
}
.year-2021 .cell {
  font-family:'FloatingMono';
  font-weight:450;
  font-size:1.8vw;
  line-height:1.5vw;
}
.year-2023 .cell {
  font-family:'FaroBold';
  font-weight:450;
  font-size:1.6vw;
  line-height:1.5vw;
}
.cell-shared {
  pointer-events:none;
}
.cell-content {
  margin:5px;
}
.year-2023 .cell.font-1 .cell-content .cell-author{
  font-family:'Faro';
  font-style:normal;
  display:block;
  font-size:1vw;
  margin-top:0.5vw;
  transform:scaleX(2.5);
}
.year-2023 .cell.font-1 .cell-content .cell-author .fixauth{
  font-size: 0.85vw;
}
.cell.alt .cell-author {
  font-family:'NimbusRom';
  font-weight:normal;
  font-style: italic;
  display:block;
  /* margin-top:2px; */
}
.year-2021 .cell.alt .cell-author, .year-2021 .cell:not(.alt) .cell-author {
  font-family:'NimbusSansCond';
  font-style:normal;
  display:block;
  font-size:1vw;
  margin-top:0.5vw;
}
.year-2023 .cell.alt .cell-author, .year-2023 .cell:not(.alt) .cell-author {
  font-family:'Faro';
  font-style:normal;
  display:block;
  font-size:1vw;
  margin-top:0.5vw;
}
/* .horizontal .cell.alt .cell-author { */
  /* font-size:2vw; */
  /* line-height:2vw; */
/* } */
.horizontal .cell:not(.alt) .cell-author {
  font-size:1.4vw;
  line-height:1.6vw;
}
.cell:not(.alt) .cell-author {
  margin-top:4px;
  font-family:'nimbus';
  display:block;
  text-align:center;
  letter-spacing:0px;
  line-height:1vw;
  font-size:0.8vw;
}
.year-2021 .cell:not(.alt) .cell-author {
  font-family:'NimbusSansCond';
  font-size:1vw;
}
.cell-content:not(.disabled):hover {
  /* filter:invert(1); */
  cursor:pointer;
}
.grid-container * {
  border-color:rgba(0,0,0,0.4) !important;
}
.year-2023 .grid-container-container{
  padding-top: 30px;
}
.grid-container .row-shared {
  margin-left:-8px;
  width:calc(100% + 16px);
}
.row-1 {
  position:absolute;
  height:25%;
  width:100%;
  border-bottom:1px solid;
  border-top:1px solid;
  left:0px;
  top:0px;
}
.row-2 {
  position:absolute;
  height:25%;
  width:100%;
  border-bottom:1px solid;
  left:0px;
  top:25%;
}
.row-3 {
  position:absolute;
  height:25%;
  width:100%;
  border-bottom:1px solid;
  left:0px;
  top:50%;
}
.cell-4 {
  position:absolute;
  width:25%;
  height:100%;
  border-right:1px solid;
  left:0%;
  bottom:0px;
}
.cell-5 {
  position:absolute;
  width:25%;
  height:100%;
  border-right:1px solid;
  left:25%;
  bottom:0px;
}
.cell-6 {
  position:absolute;
  width:25%;
  height:100%;
  border-right:1px solid;
  left:50%;
  bottom:0px;
}
.window-content {
  height:100%;
  overflow:scroll;
}
.window-content span{
  font-family: 'nimbus';
  font-size: 0.7em;
}
.window-content > * {
  width: 100%! important;
  height: 100% !important;
}
.app-header-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.album-tracks {
  font-size:10px;
  display:flex;
  flex-direction: column;
  margin-left:10px;
}
.album-bottom {
  display:flex;
  flex-direction: row;
}
.window.narrow .albums-container {
  flex-direction: column;
}
.albums-container {
  font-size:12px;
  font-family:'nimbus';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* display: flex; */
  /* flex-direction: column; */
}
.mobile .album-cover-container {
  width:160px;
  height:160px;
}
.mobile .single-track .album-cover-container {
  width:250px;
  height:250px;
}
.single-track .album-cover-container {
  width:250px;
  height:250px;
}
.album-cover-container {
  background:#fefefe;
  border-radius:2px;
  width:210px;
  height:210px;
  cursor:pointer;
  position:relative;
  margin-bottom:10px;
}
.play-btn.playing:after {
  content:'';
  position:absolute;
  left:18px;
  width:12px;
  height:30px;
  background:black;
}
.play-btn {
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  width:12px;
  height:30px;
  background:black;
  margin-left:-4px;
}
.play-btn.paused {
  margin-left:4px;
  background:transparent;
  width:0px;
  height:0px;
  border-right: 18px solid transparent;
  border-left: 28px solid black;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  transform:translate(calc(-50% + 12px),-50%);
}
.mobile .album-cover-container {
  width:160px;
  height:160px;
  margin-bottom:20px;
}
.gallery-image {
  width:100%;
  height:auto;
  transition:all ease-in-out 1s;
}
.mobile .album-cover {
  width:160px;
  height:160px;
}
.single-track .album-cover {
  width:250px;
  height:250px;
  transition:all ease-in-out 1s;
}
.album-cover {
  width:210px;
  height:210px;
  transition:all ease-in-out 1s;
}
.gallery-image-loading,
.album-cover.cover-loading {
  filter:blur(4px);
}
.gallery-image-loaded,
.album-cover.cover-loaded {
  filter:blur(0px);
}

.audio-player-container {
  position:absolute;
  visibility:none;
}
.album-track .album-track-text {
  cursor:pointer;
  position:relative;
  display:inline-block;
}
.album-track.active .album-track-text:after {
  display:inline-block;
  background:#231f21;
  content:'';
  width:100%;
  position:absolute;
  left:0px;
  height:1px;
  top:50%;
  transform:translateY(-50%);
}
.album-title {
  margin-bottom:4px;
}
.window-category {
  font-size:10px;
  font-family:'cmuntb';
}
.mobile  .window-corner {
  top:5px;
  right:10px;
}
.window-corner {
  position:absolute;
  top:6px;
  right:20px;
  display:flex;
}
.window-date {
  margin-left:10px;
  font-size:10px;
  font-family:'cmuntb';
}
.pdf-container .react-pdf__Page__canvas {
  width:100% !important;
  height:auto !important;
}
.loading-pdf {
  font-size:14px;
  font-family:'cmuntb';
}
.videos-container {
  display:flex;
  flex-direction:row;

}
.video-container {
  width:calc(50% - 8px);
}
.video-container:first-child {
  margin-right:16px;
}
.video-container > * {
  width: 100%! important;
  height: 100% !important;
}
.divider {
  width:100%;
  height:1px;
  border-top:1px solid black;
  margin:40px 0px;
}
.app-cover img {
  width:100%;
  height:100%;
}
.app-cover {
  width:100%;
  height:100%;
}

.mouse-pointer-cover {
  border-radius:50%;
  background:white;
  position:absolute;
  width:200px;
  height:200px;
  background-image:url("./media/sch2.png");
  background-size:100vw auto;
}
.mobile .mouse-pointer-cover {
  background-image:url("./media/sch3.png");
}
.cover-mouse {
  overflow:hidden;
  width:200px;
  height:200px;
  position:absolute;
  left:0;
  top:0;
  background:white;
  border-radius:50%;
}
.window-footnote {
  font-family:'cmuntb';
  font-size:10px;
  margin-top:4px;
  margin-bottom:-1px;
  bottom: 10px;
}
.zoom-controls {
  display:flex;
  position:absolute;
  bottom:3px;
  right:100px;
  line-height:8px;
  font-size:10px;
}
.zoom-controls-in {
  cursor:pointer;
  display:inline-block;
  padding:5px;
}
.zoom-controls-out {
  cursor:pointer;
  display:inline-block;
  padding:5px;
  margin-right:5px;
}

.gallery-controls {
  position:absolute;
  bottom:1px;
  right:20px;
}
.gallery-control-page {
  font-size:12px;
  display:inline-block;
  margin-right:20px;
  font-family:'cmuntb';

}
.gallery-control-right {
  cursor:pointer;
  font-size:12px;
  display:inline-block;
  padding:5px;
}
.gallery-control-left {
  padding:5px;
  cursor:pointer;
  font-size:12px;
  display:inline-block;
  margin-right:10px;
}
.window-pdf-canvas {
  height: auto;
  width: 100%;
}
.window-pdf-canvas canvas {
  width: 100%;
  height: calc(100% - 50px);
}
/* pdf react navigation */
.container.rounded .row {
  background:white;
  height:32px;
  border:none;
}
.container.rounded .row * {
  color:black;
}
.window-pdf-canvas-container > div > div:last-child {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.App .mobile .row {
  margin:0px !important;
}
.App .mobile .window-content-container .row {
  display: flex;
  flex-direction: column;
}

.mobile .window-pdf-canvas-container > div > div:last-child .col-sm-4 {
  position:inherit;
  padding:0px;
  width:auto;
}
.mobile .bg-dark {
  background-color:transparent !important;
  padding:0px !important;
  margin-left:-20px;
}

.window-content-container .row .col-sm-4 {
  font-size:12px;
  font-family:'cmuntb';
}


.window-content-container .row .col-sm-4:nth-child(2) {
  flex: 1;
  max-width: 100%;
  text-align: right;
  margin-right:20px;
}
.window-content-container .row .col-sm-4:nth-child(1) button:nth-child(2),
.window-content-container .row .col-sm-4:nth-child(3) {
  display:none;
}

.mobile .window-content-container .row .col-sm-4:nth-child(3) button:nth-child(2),
.mobile .window-content-container .row .col-sm-4:first-child button:nth-child(2) {
  display: none;
}
.app-header-floating-mobile:visited,
.app-header-floating-mobile:active,
.app-header-floating-mobile:hover,
.app-header-floating-mobile {
  text-decoration:none;
  color:black;
}
.background-schedule {
  position:absolute;
  pointer-events:none;
  width: 100vw;
  top: 0;
}
.app-cover {
  height:100vh;
  width:100vw;
  overflow:hidden;
}
.impressium:hover {
  cursor:pointer;
}
.impressium {
  font-weight:bold;
  text-decoration:underline;
}
.link:visited,
.link {
  text-decoration:none;
  color:black;
}

.gallery-page-container {
  height: 100%;
  width: 100%;
  display:flex;
  justify-content:center;
}
.gallery-page-container  img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width:auto;
  height:auto;
  width:100%;
}
.gallery-image-loading {
  filter:blur(4px);
  /* width:100%; */
  /* height:100%; */
}
.cover-mobile .mover-mouse {
  display:none;
}
.App.mobile {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
}
.window-pdf-canvas-container .gallery-controls {
  left: 0;
  width: calc(100% - 60px);
  right: 0;
  display:flex;
  justify-content: space-between;
}
.pdf-zoom-group {
  display:flex;
  width:50px;
  justify-content:space-between;
}
.pdf-zoom-group div {
  cursor:pointer;
}
.homepage-video-container {
  width:100vw !important;
  height:100vh !important;
  background:black;
}
.homepage-video-container > div {
  width:100vw !important;
  height:calc(100vh + 4px) !important;
  margin-top:-4px;
}
.homepage-video-container video {
  object-fit:cover;
}

.vjs-poster { background-size:cover; }

.window-pdf-canvas,
.window-pdf-canvas-container > div,
.window-pdf-canvas-container {
  height:100%;
  width:100%;
}
.window-pdf-canvas canvas {
  width:auto !important;
  height: 100% !important;
  margin: auto;
  display: flex;
}
.zoom-freedom {
  width:auto !important
}
.mobile  .app-cover img {
  width: auto;
  height: 100%;
  margin-left: -50%;
}

.cover-mobile-horizontal video {
  object-fit:cover;
  object-position: 50% bottom
}
.cover-mobile-horizontal {
  width:100vw;
  height:calc(100vh - 140px);
  overflow:hidden;
  position:absolute;
  margin-top:140px;
  top:0px;
}
.cover-mobile video {
  object-fit:cover;
  object-position: right bottom
}
.cover-mobile-horizontal .vjs-has-started .vjs-poster {
  background-position:bottom;
}
.cover-mobile .vjs-has-started .vjs-poster {
  background-position:right;
}
.cover-mobile {
  width:100vw;
  overflow:hidden;
  position:absolute;
  height:calc(100% - 110px);
  margin-top:110px;
  top:0px;
}
.cover-mobile > div {
  height:calc(100% + 4px) !important;
  margin-top:-4px;
}

/* [> <] */
/* .cover-mobile { */
  /* width:100vw; */
  /* overflow:hidden; */
/* } */
/* .app-content-cover { */
  /* background-image:url('./media/atlas2_sample.png'); */
  /* [> background-position: center; <] */
/* } */
/* .horizontal .app-content-cover-mobile { */
  /* margin-top:140px; */
/* } */
/* .app-content-cover-mobile { */
  /* margin-top:110px; */
/* } */
/* .app-content-cover-mobile { */
  /* position:absolute; */
  /* left:0px; */
  /* top:0px; */
  /* opacity:1; */
  /* overflow:hidden; */
  /* background-image:url('./media/atlas2_sample.png'); */

  /* width: 100%; */
  /* background-position: center; */
  /* height: calc(100% - 100px); */
  /* background-size:cover; */
/* } */
/* .horizontal .app-content-cover-mobile { */
  /* height: calc(100% - 140px); */
  /* background-position: center bottom; */
/* } */
.cover-mobile-container-cover {
  height:50vh;
  overflow:hidden;
}
.text-footnote {
  font-size:13px;
  font-family:'Helvetica Neue';
}
.app-header > * {
  margin: auto 0;
}
.cover-mobile .window.narrow {
  height:calc(90% + 4px) !important;
}
.cover-mobile > div:first-child {
  pointer-events:none !important;
}
.cover-mobile video,
.cover-mobile-horizontal video {
  pointer-events:none;
}


.window.narrow.id-82,
.window.narrow.id-320,
.window.narrow.id-281,
.window.narrow.id-260,
.window.narrow.id-240,
.window.narrow.id-201,
.window.narrow.id-380,
.window.narrow.id-120,
.window.narrow.id-1,
.window.narrow.id-180,
.window.narrow.id-340,
.window.narrow.id-181 {
  height: 70% !important;
}
.window.narrow.id-121 {
  height: 60% !important;
}
.one-word {
  white-space:nowrap;
}


/* Curatorial */

.year-2021 .curatorial .quote {
  font-style:italic;
  text-align:center;
}

.year-2021 .curatorial .title {
  text-decoration:underline;
}

.linethrough {
  text-decoration: line-through;
}
.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_inner {
  position: relative;
  padding: 10px;
  background: #fff;
  border:2px solid #231f21;
  border-radius: 5px;
  width: 70%;
  max-width: 700px;
  font-family:'NimbusRom';
  line-height:22px;
  font-size:1.5em;
  text-align: left;
  line-height: 1em;
}
.popup_inner button{
  float:inline-start;
  border: #fff;
  background-color: #fff;
  color: black;
  font-size: 15px;
}
.cinecologies{
  padding-left: 70px;
}
.cinecologies2{
  padding-left: 35px;
}
